import {UserAdminApi, UserAdminRoutes} from '@peachy/web-dashboard-pure'
import {FunctionUrlClient} from '@peachy/core-domain-client'
import {Auth} from '@aws-amplify/auth'
import {Signer} from '@aws-amplify/core'


export function createUserAdminApi(routes: UserAdminRoutes): UserAdminApi {
    const functionUrlClient = new FunctionUrlClient(Auth, Signer)
    return {
        async newDashUserPassword(email: string, password?: string) {
            return functionUrlClient.postLambda(routes.newDashUserPassword, {email, password})
        },
        async newCustomerPassword(email: string, password?: string) {
            return functionUrlClient.postLambda(routes.newCustomerPassword, {email, password})
        },
    }
}
