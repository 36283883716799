import {HealthHeroService} from './HealthHeroService'
import {capitalize} from 'lodash-es'
import {AppointmentOut, BookingOut, ConsultationType, Gender, PatientRecordIn} from '@peachy/health-hero-client'
import {EnquiryReader} from './enquiry/EnquiryReader'
import {QuestionIds} from './enquiry/types'
import {EnquiryDefinitionService} from './enquiry/EnquiryDefinitionService'
import {EnquiryService} from './enquiry/EnquiryService'
import {LifeService} from './LifeService'
import {AppointmentProviderService} from './AppointmentsService'
import {
    Address,
    Appointment,
    AppointmentOrInProgressBooking,
    Enquiry,
    EnquiryDefinitionId,
    GenderPreferenceOptionId,
    GenderPreferenceOptionIds,
    InProgressAppointmentBooking,
    Life,
    Media,
    peachyAddressFromLookupAddress,
    Question,
    QuestionOption,
    VideoOrPhoneOptionId
} from '@peachy/repo-domain'
import {BookVirtualGpEnquiryDefinition} from './enquiry/definition/book-virtual-gp/BookVirtualGpEnquiryDefinition'
import {GeoLocationServiceClient} from '@peachy/client-kit/src/services/geo-location/GeoLocationServiceClient'

export class VirtualGpService implements AppointmentProviderService {

    public readonly bookingEnquiryDefinitionId: EnquiryDefinitionId = 'book-virtual-gp'
    protected readonly enquiryReader = EnquiryReader.forVgpBooking()

    constructor(protected readonly lifeService: LifeService,
                protected readonly addressLookupService: GeoLocationServiceClient,
                protected readonly enquiryService: EnquiryService,
                protected readonly enquiryDefinitionService: EnquiryDefinitionService,
                protected readonly healthHeroService: HealthHeroService) {}

    getTerms() {
        return this.healthHeroService.listTerms()
    }

    async putAppointmentOnHold(vgpProviderAppointmentId: string) {
        const accountHolder = await this.lifeService.getPrimaryLife()
        return this.healthHeroService.holdAppointment(accountHolder.vgpUserId!, vgpProviderAppointmentId)
    }

    async cancelBooking(appointment: Appointment) {
        await this.cancelAndOrRebookVirtualGpBooking(appointment)
    }

    async cancelBookingAndRebook(appointment: Appointment, rebookingQuestion: Question) {
        return this.cancelAndOrRebookVirtualGpBooking(appointment, rebookingQuestion.getFirstAnswer<QuestionOption>().id)
    }

    async confirmBooking(bookingInProgress: InProgressAppointmentBooking): Promise<Appointment> {
        const enquiry = bookingInProgress.enquiry
        const primaryLife = await this.lifeService.getPrimaryLife()
        const bookingData = await this.extractBookingDataFrom(enquiry, primaryLife)
        // should we update for all lives? just the primary? or just whoFor? (nb: booking question references primary life's GP always at mo)
        await this.lifeService.updateRegisteredGpOf(primaryLife, bookingData.registeredGp)
        const booking = await this.makeBookingWithServiceProvider(primaryLife, bookingData)
        return this.buildAppointment(bookingInProgress, booking, bookingData.address!, bookingData.whoFor!, bookingData.contactMethod!)
    }

    getChooseAppointmentDateQuestion(): Question {
        return this.enquiryDefinitionService.getDefinition<BookVirtualGpEnquiryDefinition>('book-virtual-gp').whatDateAndTime()
    }

    async acceptTermsAndCreateVgpAccountIfNotAlreadyCreated(termsAcceptedIds: string[]) {
        let accountHolder = await this.lifeService.getPrimaryLife()
        await this.lifeService.markVgpTermsAsAccepted(accountHolder, termsAcceptedIds)
        const vgpAccountHolderId = accountHolder.vgpUserId || (await this.healthHeroService.registerOrUpdateAccountHolder(accountHolder))?.id
        await this.lifeService.updateVgpUserIdOf(accountHolder, vgpAccountHolderId)
        await this.healthHeroService.acceptTerms(vgpAccountHolderId!, termsAcceptedIds)
    }

    async getVideoConsultationSessionDetailsFor(appointment: Appointment) {
        let accountHolder = await this.lifeService.getPrimaryLife()
        const booking = await this.healthHeroService.getBooking(accountHolder.vgpUserId!, appointment.getInMetadata<string>(metadataBookingIdKey)!)
        const {consultations: consultation, sessionDetails} = booking

        return {
            ...sessionDetails,
            userId: accountHolder.vgpUserId,
            consultationId: consultation!.id,
            eventHub: await this.healthHeroService.getConsultationEventHubFor(accountHolder.vgpUserId!, Number(consultation!.id), sessionDetails.token)
        }
    }

    async lookupNextAvailableAppointments(vgpUserId: string | undefined, contactMethod: VideoOrPhoneOptionId | undefined, gpGender: GenderPreferenceOptionId | undefined) {
        return vgpUserId ? this.healthHeroService.listAllGpAppointmentsAvailableTo(
            vgpUserId,
            contactMethod ? capitalize(contactMethod) as ConsultationType : undefined,
            gpGender && gpGender != GenderPreferenceOptionIds.NO_PREFERENCE ? capitalize(gpGender) as Gender : undefined,
        ) : [] as AppointmentOut[]
    }

    getBookingConfirmationMessage() {
        return `All done!

We'll notify you 15 mins before your appointment inviting you to join the session`
    }

    private async extractBookingDataFrom(enquiry: Enquiry, accountHolder: Life) {
        const whoForId = this.enquiryReader.extractWhoForIdFrom(enquiry)
        const whoFor = await this.lifeService.getLife(whoForId)

        return {
            whoFor,
            contactMethod: this.enquiryReader.extractContactMethodFrom(enquiry),
            contactNumber: enquiry.getFirstAnswerTo<string>(QuestionIds['new-phone-number']) || accountHolder.phoneNumber,
            appointmentId: this.enquiryReader.extractAppointmentIdFrom(enquiry),
            reason: enquiry.getFirstAnswerTo<string>(QuestionIds['appointment-reason']),
            photos: enquiry.getAnswersTo<Media>(QuestionIds['capture-related-medical-docs']),
            registeredGp: this.enquiryReader.extractNewRegisteredGpFrom(enquiry) || accountHolder.registeredGp,
            address: await this.extractWhereWillTheyBeDuringTheAppointment(accountHolder.address, enquiry)
        }
    }

    private async extractWhereWillTheyBeDuringTheAppointment(homeAddress: Address, enquiry: Enquiry) {
        const elsewhereForAppointment = this.enquiryReader.extractWhereWillYouBeFrom(enquiry)
        if (elsewhereForAppointment) {
            const lookupAddress = await this.addressLookupService.fetchAddress({addressId: elsewhereForAppointment.id})
            return peachyAddressFromLookupAddress(lookupAddress) ?? homeAddress
        } else {
            return homeAddress
        }
    }

    protected async makeBookingWithServiceProvider(accountHolder: Life, bookingData: any) {
        // just in case!
        await this.healthHeroService.holdAppointment(accountHolder.vgpUserId!, bookingData.appointmentId)
        const patient = bookingData.whoFor
        const patientVgpId = await this.createOrUpdatePatientRecord(accountHolder, patient, bookingData)

        return this.healthHeroService.makeBooking(
            accountHolder.vgpUserId!,
            patientVgpId,
            bookingData.appointmentId,
            capitalize(bookingData.contactMethod) as ConsultationType,
            bookingData.reason,
            bookingData.address,
            bookingData.contactNumber,
            bookingData.photos
        )
    }

    private async createOrUpdatePatientRecord(accountHolder: Life, patient: Life, bookingData: any) {
        const accountHolderVgpId = accountHolder.vgpUserId!
        const patientRecord = this.healthHeroService.buildPatientRecord(
            patient,
            (patient.address ?? accountHolder.address)!,
            (patient.phoneNumber ?? accountHolder.phoneNumber)!,
            bookingData.registeredGp
        )

        if (patient.is(accountHolder)) {
            await this.healthHeroService.createOrUpdatePatientRecord(accountHolderVgpId, patientRecord)
            return accountHolderVgpId
        } else {
            const [dependentVgpId, wasAlreadyRegistered] = await this.registerDependantIfNotAlreadyRegistered(accountHolderVgpId, patient, patientRecord)
            if (wasAlreadyRegistered) {
                await this.healthHeroService.updateDependantPatientRecord(accountHolderVgpId, dependentVgpId, patientRecord)
            }
            return dependentVgpId
        }
    }

    private async registerDependantIfNotAlreadyRegistered(accountHolderVgpId: string, dependant: Life, dependantPatientRecord: PatientRecordIn) {
        let alreadyRegistered = !!dependant.vgpUserId
        if (!alreadyRegistered) {
            const vgpUserId = (await this.healthHeroService.registerDependant(accountHolderVgpId, dependantPatientRecord)).id
            dependant = await this.lifeService.updateVgpUserIdOf(dependant, vgpUserId)
        }
        return [dependant.vgpUserId, alreadyRegistered] as [string, boolean]
    }

    protected buildAppointment(appointmentOrInProgressBooking: AppointmentOrInProgressBooking, booking: BookingOut, location: Address, whoFor: Life, contactMethod: string) {
        const date = booking.appointments!.start
        const whoWith =  booking.clinicalPractitioners!.firstName + ' ' + booking.clinicalPractitioners!.lastName
        const takesPlaceInApp =  booking.consultationType === 'Video'
        const appointment = new Appointment(
            appointmentOrInProgressBooking.id,
            appointmentOrInProgressBooking.type,
            appointmentOrInProgressBooking.dateCreated,
            appointmentOrInProgressBooking.enquiryId,
            date,
            whoFor,
            whoWith,
            location,
            takesPlaceInApp
        )
        appointment.setInMetadata(metadataBookingIdKey, booking.id)
        appointment.setInMetadata(metadataContactMethodKey, contactMethod)
        return appointment
    }

    private async cancelAndOrRebookVirtualGpBooking(appointment: Appointment, vgpProviderAppointmentIdForReebook?: string) {
        const originalBookingId = appointment.getInMetadata<string>(metadataBookingIdKey)
        const accountHolder = await this.lifeService.getPrimaryLife()

        if (vgpProviderAppointmentIdForReebook) {
            const enquiry = await this.enquiryService.get(appointment.enquiryId)
            await this.putAppointmentOnHold(vgpProviderAppointmentIdForReebook)
            const bookingData = await this.extractBookingDataFrom(enquiry, accountHolder)
            bookingData.appointmentId = vgpProviderAppointmentIdForReebook
            const booking = await this.makeBookingWithServiceProvider(accountHolder, bookingData)
            appointment = this.buildAppointment(appointment, booking, bookingData.address!, bookingData.whoFor!, bookingData.contactMethod!)
        }
        await this.cancelBookingWithServiceProvider(accountHolder, originalBookingId!)
        return appointment
    }

    private async cancelBookingWithServiceProvider(accountHolder: Life, bookingId: string) {
        return this.healthHeroService.cancelBooking(accountHolder.vgpUserId!, bookingId)
    }
}

const metadataBookingIdKey = 'bookingId'
const metadataContactMethodKey = 'contactMethod'
