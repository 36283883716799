import 'reflect-metadata'
import {Type} from 'class-transformer'
import {Address} from './Address'
import {RegisteredGp} from './RegisteredGp'
import {Gender, LifeType} from './types'
import {IsoDatePartOnly, joinWhereDefined} from '@peachy/utility-kit-pure'
import {differenceInYears, parseISO} from 'date-fns'

export class Life {

    @Type(() => Address)
    readonly address: Address | undefined
    @Type(() => RegisteredGp)
    readonly registeredGp: RegisteredGp

    constructor(
        readonly id: string,
        readonly awsSub: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly email: string | undefined,
        readonly phoneNumber: string | undefined,
        readonly dateOfBirth: IsoDatePartOnly,
        readonly gender: Gender | undefined,
        readonly onboarded: boolean,
        readonly type: LifeType,
        readonly isPolicyOwner: boolean,
        readonly isPrimary: boolean,
        readonly dateCancelled: Date | undefined,
        address: Address | undefined,
        registeredGp: RegisteredGp,
        readonly vgpUserId: string | undefined) {

            this.address = address
            this.registeredGp = registeredGp
    }

    get fullName() {
        return joinWhereDefined(' ', this.firstName, this.lastName)
    }

    get initials() {
        return this.firstName[0] + this.lastName[0]
    }

    get ageInWholeYears() {
        const dob = parseISO(this.dateOfBirth)
        const now = new Date()
        return differenceInYears(now, dob)
    }

    get isCancelled() {
        return !!this.dateCancelled
    }

    is(otherLife?: Life) {
        return this.id === otherLife?.id
    }
}
