import {Modal} from '@peachy/client-kit'
import {Component, createSignal} from 'solid-js'
import {CancelButtons} from './CancelButtons'
import {CancelMembers} from './CancelMembers'
import {CancelModalContactDetails} from './CancelModalContactDetails'
import {CancelReason} from './selector/CancelReason'
import styles from './Modal.module.css'

export const CancelPolicyModal: Component<{ isOpen: boolean, onDismiss: () => void }> = (props) => {

    // TODO temporary. Need to understand data model we send when cancel
    const [canCancel, setCanCancel] = createSignal(false)

    const onDismiss = () => {
        setCanCancel(false)
        props.onDismiss()
    }

    return (
        <Modal isOpen={props.isOpen} class={styles.modal}>
            <section>
                {/* TODO text later depending on member type */}
                <p>Are you sure you want to cancel this policy?</p>
                <CancelReason onSelect={() => setCanCancel(true)} />
                <CancelModalContactDetails />
                <CancelMembers />
                <CancelButtons canCancel={canCancel()} onDismiss={onDismiss} />
            </section>
        </Modal>
    )
}
