import {Spinner} from '@peachy/client-kit'
import {AccountSubscriptionDto} from '@peachy/core-domain-pure'
import {useParams} from '@solidjs/router'
import {createContext, createResource, ParentComponent, Show, useContext} from 'solid-js'
import {useSubscriptionService} from '../../../controllers/DashboardServiceController'

const AccountSubscriptionContext = createContext<AccountSubscriptionDto>()

export const useAccountSubscription = () => useContext(AccountSubscriptionContext)

export const SubscriptionProvider: ParentComponent = (props) => {
    const [subscriptionRequest] = createResource(loadSubscription)

    return (
        <>
            {/* TODO use Suspense */}
            <Show when={!subscriptionRequest.loading && subscriptionRequest()}>
                <AccountSubscriptionContext.Provider value={subscriptionRequest()}>
                    {props.children}
                </AccountSubscriptionContext.Provider>
            </Show>
            <Show when={subscriptionRequest.loading}>
                <Spinner isShown />
            </Show>
            <Show when={!subscriptionRequest.loading && !subscriptionRequest()}>
                <h3>Not found &#128549;</h3>
            </Show>
        </>
    )
}

const loadSubscription = async () => {
    const subscriptionService = useSubscriptionService()
    const {accountId, subscriptionId} = useParams()
    return await subscriptionService.adminLoadActiveSubscription({
        accountId, subscriptionId
    })
}
