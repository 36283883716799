import {ApplicationContextFactory, BeanFactory, LambdaApiProvider, PeachyClient} from '@peachy/service'
import {FlashRepo, FlashStore, MemFlashDb} from '@peachy/flash-repo-pure'
import {newUUID} from '@peachy/utility-kit-pure'
import {PeachyFlashRepo} from '@peachy/flash-repo-peachy-client'
import {PeachyRepoRoot} from '@peachy/repo-domain'
import LRUMap from 'mnemonist/lru-map'
import {AdminAuthenticatedRemoteFlashRepoAdapter} from './AdminAuthenticatedRemoteFlashRepoAdapter'
import {useApiInfra, useAuthInfra} from '../../controllers/InfraController'
import {useServicePatch} from '@peachy/client-kit'
import {AuthClass} from '@aws-amplify/auth/lib-esm/Auth'
import {APIClass} from '@aws-amplify/api'
import {Storage} from '@aws-amplify/storage'
import {Signer} from '@aws-amplify/core'

const DEFAULT_BRANCH = 'client-policy'
const REMOTE_REPO_ID = 'CORE-REPO'

const cache = new LRUMap<string, BeanFactory>(4)
type Options = {
    branch?: string
    amplifyAuth?: AuthClass
    amplifyApi?: APIClass
    storageApi?: typeof Storage
}
export class WebCustomerApplicationContext {

    public static async for(ownerId: string, options?: Options) {
        const branch = options?.branch ?? DEFAULT_BRANCH
        const amplifyAuth = options?.amplifyAuth ?? useAuthInfra()
        const amplifyApi = options?.amplifyApi ?? useApiInfra()
        const storageApi = options?.storageApi //todo storage is never configured in web to default to

        let factory = cache.get(ownerId)

        if (!factory) {

            const repo = await openInMemoryFlashRepo(ownerId, branch)

            factory = ApplicationContextFactory.buildOnDemandFactory({
                peachyClient: build => {
                    return new PeachyClient(
                        amplifyApi,
                        storageApi,
                        WebCustomerApplicationContext.buildLambdaApiProvider(build.servicePatchUri(), amplifyAuth),
                        build.logger()
                   )
                },
                servicePatchUri: () => useServicePatch(),
                config: () => config(),
                enquiryDefinitions: () => [],
                peachyFlashRepo: () => repo,
                remoteRepoAdapter: build => {
                    return new AdminAuthenticatedRemoteFlashRepoAdapter(
                        build.peachyClient(),
                        REMOTE_REPO_ID,
                        ownerId
                )},
                imageCompressor: () => undefined, //todo
                healthHeroClient: () => undefined, //todo
            })
            cache.set(ownerId, factory)
        }
        return factory
    }

    public static buildLambdaApiProvider(servicePatchUri = useServicePatch(), auth = useAuthInfra()) {
        return new LambdaApiProvider(
            servicePatchUri,
            auth,
            Signer
        )
    }
}


//todo get this from <branch>.json?
function config() {
    return {ascenti: {bookingPhoneNumber: '0345 319 0474'}, healthHero: {bookingPhoneNumber: '0330 678 0850'}}
}

async function openInMemoryFlashRepo(ownerId: string, branch: string): Promise<PeachyFlashRepo> {
    const flashDb = new MemFlashDb('dashboard-repo')
    const flashStore = await FlashStore.createFlashStore(flashDb)
    const installationId = 'dashboard-' + newUUID()
    return new FlashRepo<PeachyRepoRoot>(installationId, branch, flashStore)
}
