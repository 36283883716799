import styles from './CancelSubscription.module.css'
import {SubscriptionProvider, useAccountSubscription} from './SubscriptionProvider'
import {Members} from './Members'
import {ukStyleDate} from '@peachy/utility-kit-pure'
import {Component} from 'solid-js'
import {MrPolicy, MrSubscription, toClass} from '@peachy/core-domain-pure'
import {DetailsField} from './DetailsField'
import {CancelSubscriptionModal} from './CancelModal/CancelSubscriptionModal'
import {createToggleSignal} from '@peachy/client-kit'

const CancelSubscription = () => {
    const [isOpen, toggleIsOpen] = createToggleSignal(false)

    return (
        <SubscriptionProvider>
            <div class={styles.container}>
                <h3>Subscription:</h3>
                <ContactDetails />

                <button onClick={toggleIsOpen}>Cancel subscription</button>
                <CancelSubscriptionModal isOpen={isOpen()} onDismiss={toggleIsOpen}/>

                <h3>Members:</h3>
                <Members/>
            </div>
        </SubscriptionProvider>
    )
}

const ContactDetails: Component = () => {
    const accountSub = useAccountSubscription()
    const account = accountSub.account
    const subscription = toClass(accountSub.subscription, MrSubscription)


    const postCode = () => {
        if (account.type === 'COMPANY') {
            return account.company.address.postcode
        }
        return toClass(accountSub.subscription.policies[0], MrPolicy).getPrimaryLife().address.postcode
    }

    return <div class={styles.details}>
        <DetailsField key='Name' value={subscription.contactName} />
        <DetailsField key='Ref' value={subscription.referenceNumber()} />
        <DetailsField key='Start Date' value={ukStyleDate(new Date(subscription.startDate))} />
        <DetailsField key='Status' value={subscription.status} />
        <DetailsField class={styles.wideField} key='Customer type' value={account.type} />
        <DetailsField key='Email' value={account.contactEmail} />
        <DetailsField key='Postcode' value={postCode()} />
    </div>
}

export default CancelSubscription
