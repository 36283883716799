import {
    Address,
    ConsultationType,
    Gender,
    HealthHeroClient,
    PatientRecordIn,
    UserOut,
    UserPatchIn
} from '@peachy/health-hero-client'
import {capitalize, isEmpty, uniqBy} from 'lodash-es'
import {concurrentlyIgnoringErrors, Logger} from '@peachy/utility-kit-pure'
import {
    Address as PeachyAddress,
    healthHeroAddressFromPeachyAddress,
    healthHeroSafePhoneNumber,
    Life,
    Media,
    Pharmacy,
    RegisteredGp
} from '@peachy/repo-domain'

export interface ImageCompressor {
    toCompressedJpgBase64Data(media: Media): Promise<string>
}

export class HealthHeroService {
    constructor(protected readonly logger: Logger,
                protected readonly hh: HealthHeroClient,
                protected readonly imageCompressor: ImageCompressor) {
    }

    async listTerms() {
        return this.hh.listTerms()
    }

    async acceptTerms(userId: string, termsIds: string[]) {
        return Promise.all(termsIds.map(termsId => this.hh.acceptTerms(userId, termsId, true)))
    }

    async holdAppointment(userId: string, appointmentId: string) {
        return this.hh.holdAppointment(userId, appointmentId)
    }

    async cancelBooking(userId: string, bookingId: string) {
        return this.hh.cancelBooking(userId, bookingId)
    }

    async listAllGpAppointmentsAvailableTo(userId: string, consultationType: ConsultationType | undefined, gpGender: Gender | undefined) {
        const appointments = await this.hh.listAllAppointments(userId, {filter: {
                consultationType: consultationType === 'Video' ? consultationType : undefined,
                'clinicalPractitioner.gender': gpGender,
                'clinicalPractitioner.specialties': ['GeneralPractitioner'],
                status: ['Available', 'OnHold']
            }})
        const validOnHoldAppointment = (onHoldUserId: string) => onHoldUserId && onHoldUserId === userId

        const firstAvailableAppointmentForEachTimeSlot = uniqBy(appointments.filter(it => it.status === 'Available' || validOnHoldAppointment(it.hold?.userId)), it => it.start.getTime())
        const heldAppointments = appointments.filter(it => validOnHoldAppointment(it.hold?.userId))

        // ensure held appointments are always returned
        heldAppointments.forEach(heldAppointment => {
            const indexOfAppointmentAtSameTimeAsHeldAppointment = firstAvailableAppointmentForEachTimeSlot?.findIndex(it => it.start === heldAppointment.start)
            if (indexOfAppointmentAtSameTimeAsHeldAppointment >= 0) {
                firstAvailableAppointmentForEachTimeSlot[indexOfAppointmentAtSameTimeAsHeldAppointment] = heldAppointment
            }
        })


        return firstAvailableAppointmentForEachTimeSlot
    }

    async registerOrUpdateAccountHolder(accountHolder: Life) {
        const patientRecord = this.buildPatientRecord(accountHolder, accountHolder.address!, accountHolder.phoneNumber!)
        try {
            const newUser = await this.registerNewAccountHolder(accountHolder, patientRecord)
            if (!newUser) {
                this.logger.debug('no _new_ user created so maybe the user already exists (maybe created via out of hours phone line) so try to find and update them...', accountHolder.id)
                return await this.attemptToUpdateExistingAccountHolder(accountHolder, patientRecord)
            }
            return newUser
        } catch (e) {
            this.logger.debug('user probably exists (maybe created via out of hours phone line) so try to find and update them...', accountHolder.id)
            return await this.attemptToUpdateExistingAccountHolder(accountHolder, patientRecord)
        }
    }

    private async registerNewAccountHolder(accountHolder: Life, patientRecord: PatientRecordIn) {
        this.logger.debug('create new HH account holder user for ', accountHolder.id)
        const hhUser = await this.hh.createUser({
            userName: accountHolder.email,
            email: accountHolder.email,
            clientIdentifier: accountHolder.id
        })

        this.logger.debug(`${hhUser ? 'created' : 'failed to create'} new HH account holder user for`, accountHolder.id)

        if (hhUser) {
            this.logger.debug('now create a patient record for', accountHolder.id)
            await this.createPatientRecord(hhUser.id, patientRecord)
        }

        return hhUser
    }

    private async attemptToUpdateExistingAccountHolder(accountHolder: Life, patientRecord: PatientRecordIn) {
        this.logger.debug('get HH user by email: ', accountHolder.email)
        const existingHhUser = await this.getUserByUserName(accountHolder.email)
        if (existingHhUser) {
            this.logger.debug('HH user exists so create or update their patient record', accountHolder.id)
            await this.createOrUpdatePatientRecord(existingHhUser.id, patientRecord)
            // ignore errors here as we don't really care if we failed to update HH with our customer identifier
            //should fix updating user's clientIdentifier fails with user authentication... how come? edsol.StandardApi.BusinessLogic.Exceptions.InvalidClientIdentifierException: Client identifier doesn't exist or is not enabled. move to backend
            this.logger.debug('and update their clientIdentifier', accountHolder.id)
            this.updateUser(existingHhUser.id, {id: existingHhUser.id, clientIdentifier: accountHolder.id}).catch(e => this.logger.error(e, {name: 'update-hh-clientIdentifier'}))
        }
        return existingHhUser
    }

    async createOrUpdatePatientRecord(userId: string, patientRecord: PatientRecordIn) {
        const existingPatientRecord = await this.getPatientRecord(userId)
        if (existingPatientRecord) {
            return this.updatePatientRecord(userId, patientRecord)
        } else {
            return this.createPatientRecord(userId, patientRecord)
        }
    }

    private async getUserByUserName(userName: string): Promise<UserOut | undefined>{
        return (await this.hh.listUsers({filter: {userName}}))[0]
    }

    async registerDependant(accountHolderId: string, dependantPatientRecord: PatientRecordIn) {
        // doesn't error if you try to create multiple dependants with same email so don't have to worry about dependants having been created outside the app
        return this.hh.createDependant(accountHolderId, dependantPatientRecord)
    }

    async updatePatientRecord(userId: string, patientRecord: PatientRecordIn) {
        return this.hh.updatePatientRecord(userId, patientRecord)
    }

    async createPatientRecord(userId: string, patientRecord: PatientRecordIn) {
        return this.hh.createPatientRecord(userId, patientRecord)
    }

    async getPatientRecord(userId: string) {
        return this.hh.getPatientRecord(userId)
    }

    async updateUser(userId: string, user: UserPatchIn) {
        return this.hh.updateUser(userId, user)
    }

    async updateDependantPatientRecord(userId: string, dependantId: string, patientRecord: Partial<PatientRecordIn>) {
        return this.hh.updateDependant(userId, dependantId, patientRecord)
    }


    async makeBooking(userId: string,
                                      patientId: string,
                                      appointmentId: string,
                                      consultationType: ConsultationType,
                                      reasonForBooking: string,
                                      address: PeachyAddress,
                                      phoneNumber: string,
                                      supportingImages: Media[]) {

        const booking = await this.hh.createBooking(
            userId,
            patientId != userId ? patientId : undefined,
            appointmentId, {
                consultationType,
                reasonForBooking,
                contactDetails: {
                    address: healthHeroAddressFromPeachyAddress(address)!,
                    phoneNumber: healthHeroSafePhoneNumber(phoneNumber)
                }
            }, {include: ['appointment', 'appointment.clinicalPractitioner']}
        )
        //don't wait for the file uploads - booking can still go ahead even if they fail
        !isEmpty(supportingImages) && this.uploadAndAttachImagesToBooking(booking.id, userId, supportingImages)
        return booking
    }

    private async uploadAndAttachImagesToBooking(bookingId: string, userId: string, images: Media[]) {
        try {
            const imageData = await concurrentlyIgnoringErrors(images.map(this.imageCompressor.toCompressedJpgBase64Data))
            const uploads = await concurrentlyIgnoringErrors(imageData.map((it, i) => this.hh.uploadFile(userId, {
                content: it!,
                fileName: `${bookingId}-${i}.jpg`
            })))
            return await concurrentlyIgnoringErrors(uploads.map((it, i) => this.hh.attachFileToBooking(userId, bookingId, it.id, `${bookingId}-${i}`)))
        } catch (error) {
            this.logger.error(error, {name: 'health-hero-image-upload'})
        }
    }

    buildPatientRecord(patient: Life,
                                       address: PeachyAddress,
                                       phoneNumber: string,
                                       patientsGp?: RegisteredGp,
                                       nominatedPharmacy?: Pharmacy,
                                       allergies?: string[]): PatientRecordIn {

        const patientRecord: PatientRecordIn = {
            firstName: patient.firstName,
            lastName: patient.lastName,
            clientIdentifier: patient.id,
            dateOfBirth: patient.dateOfBirth,
            email: patient.email,
            phoneNumber: healthHeroSafePhoneNumber(phoneNumber),
            gender: (patient.gender ? capitalize(patient.gender) : 'Unknown') as Gender,
            address: healthHeroAddressFromPeachyAddress(address)!,
            // optional data
            allergies: allergies || [],
        }

        const patientsGpAddress = healthHeroAddressFromPeachyAddress(patientsGp?.address)
        if (this.isValid(patientsGpAddress) && patientsGp?.practiceName) {
            patientRecord.gp = {
                surgery: {
                    name: patientsGp.practiceName,
                    address: patientsGpAddress!,
                }
            }
        }

        const nominatedPharmacyAddress = healthHeroAddressFromPeachyAddress(nominatedPharmacy?.address)
        if (this.isValid(nominatedPharmacyAddress) && nominatedPharmacy!.name) {
            patientRecord.nominatedPharmacy = {
                name: nominatedPharmacy!.name,
                address: nominatedPharmacyAddress!
            }
        }

        return patientRecord
    }

    async getBooking(userId: string, bookingId: string) {
        return this.hh.getBooking(userId, bookingId, {include: ['consultation']})
    }

    async getConsultationEventHubFor(userId: string, consultationId: number, consultationSessionToken: string) {
        return this.hh.buildConsultationEventHubFor(userId, consultationId, consultationSessionToken)
    }

    private isValid(address?: Address) {
        return address?.addressLine1 && address?.city && address?.postCode && address?.countryCode
    }
}
