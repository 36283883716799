import {ClaimsActivityApi} from '@peachy/claims-activity-pure'
import {GeoLocationServiceClient} from '@peachy/client-kit/src/services/geo-location/GeoLocationServiceClient'
import {Api} from '@peachy/core-domain-pure'
import {DocGenApi} from '@peachy/doc-gen-pure'
import {FlashRepoApi} from '@peachy/flash-repo-pure'
import {UserAdminApiDefinition} from '@peachy/web-dashboard-pure'

export interface ILambdaApiProvider {
    docApi(): Promise<DocApiService>
    claimsActivityApi(): Promise<ClaimsActivityApi>
    adminRepoApi(): Promise<FlashRepoApi>
    userAdminApi(): Promise<Api<typeof UserAdminApiDefinition>>
    geoLocationApi(): GeoLocationServiceClient
}

interface DocApiService extends DocGenApi {
    getPdfLink(ticketId: string, asFile: string): string
}
