import {createUserAdminService, UserAdminClient} from './user-admin/UserAdminClient'
import {createIamService, IamService} from './iam/IamService'

import {createIamApi} from './iam/IamApi'
import {createUserAdminApi} from './user-admin/UserAdminApi'
import {UserAdminApiName} from '@peachy/web-dashboard-pure'
import {makeApiGatewayClient} from '@peachy/core-domain-client'
import Auth from '@aws-amplify/auth'
import {fetchServiceConfig} from '@peachy/core-domain-client/src/fetchServiceConfig'


import {useServicePatch} from '@peachy/client-kit'
import {Signer} from '@aws-amplify/core'
import {API} from '@aws-amplify/api'
import {SubscriptionApiGateway, SubscriptionApiGatewayDefinition} from '@peachy/subscription-pure'


export type DashboardServices = {
    iamService: IamService
    userAdminService: UserAdminClient
    subscriptionService: SubscriptionApiGateway
}

export async function createDashboardServices(): Promise<DashboardServices> {

    const servicePatch = useServicePatch()

    return {
        iamService: createIamService(createIamApi()),
        userAdminService: createUserAdminService(createUserAdminApi(await fetchServiceConfig(UserAdminApiName, servicePatch))),
        subscriptionService: await makeApiGatewayClient(SubscriptionApiGatewayDefinition, API, Auth, Signer)
    }
}
