import {PropertiesOnly} from '@peachy/utility-kit-pure'
import {assign} from 'lodash-es'
import {BenefitType} from '../types'
import {Address} from '../Address'
import {TerminologyItem} from '@peachy/nhs-pure'

export class ClaimInvoiceLineItem {

    readonly id: string
    readonly procedure: TerminologyItem
    readonly treatmentAddress?: Partial<Address>
    readonly treatmentDate: Date
    readonly treatmentPaymentDate: Date
    readonly benefitType: BenefitType
    readonly planYearId: string
    readonly hospitalAdmissionId?: string
    readonly invoiceAmountInPence: number
    readonly eligibleAmountInPence: number
    readonly reasonId: string

    constructor(props: PropertiesOnly<ClaimInvoiceLineItem>) {
        assign(this, props)
    }

}
