import {
    Bookmark,
    BranchId,
    EmptyArray,
    EmptyArrayHash,
    EmptyObject,
    EmptyObjectHash,
    Flash,
    Flashable,
    Flashlet,
    FlashPath,
    FlashProp,
    FlashPropType,
    FlashPropTypes,
    FlashValue,
    HardHash,
    Hash,
    isFlashable,
    isHardHash,
    isPrimitive,
    rawHash,
} from './flash-repo-domain'
import {
    dateFromIso,
    isArray,
    isDateObject,
    isNullish,
    isNumber,
    isObject,
    isString,
    last,
    mapAsync,
    mapObjectAsync,
    toIsoDate
} from '@peachy/utility-kit-pure'
import {LRUMap} from 'lru_map'
import {IFlashDb} from './IFlashDb'
import {MultiMap} from 'mnemonist'


export interface IFlashStore {

    getDbName(): string

    hasCachedHash(hash: Hash): Promise<boolean>

    hasHash(hash: Hash): Promise<boolean>

    get(hash: Hash): Promise<Flash>

    gatherPropPath(path: FlashPath, fromHash: Hash): Promise<FlashValue[]>

    getPropValue(path: FlashPath, fromHash: Hash): Promise<FlashValue>

    put(x: Flashable): Promise<Hash>

    followPath(path: FlashPath, fromHash: Hash, follower: (prop: FlashProp, value: FlashValue, depth: number) => boolean | void): Promise<void>

    build(flashValue: FlashValue): Promise<unknown>

    getFlashValue(flash: Flash, prop: FlashProp): FlashValue

    getSlice(since: Bookmark): Promise<Flashlet[]>

    putSlice(slice: Flashlet[]): Promise<Bookmark>

    getBranchHistory(branchId: BranchId): Hash[]

    getBranchRootHash(branchId: BranchId): Hash

    getBranchRoot(branchId: BranchId): Promise<Flash>

    pushBranchRootHash(branchId: BranchId, newHash: Hash, oldHash?: Hash): Promise<boolean>

    // pushBranchRoot(branchId: BranchId, x: Flashable, oldHash?: Hash): Promise<Hash>

    getBranchIds(): BranchId[]

    getBookmark(): Promise<number>

    dispose(): Promise<void>
}



export class FlashStore implements IFlashStore {

    protected readonly flashCache: LRUMap<Hash, Flash>
    private readonly branchCache: MultiMap<string, Hash>

    protected constructor(public flashDb: IFlashDb, cacheSize = Number.MAX_SAFE_INTEGER) {
        this.flashCache = new LRUMap(cacheSize)
        this.branchCache = new MultiMap()
        this.resetCaches()
    }


    private resetCaches() {
        this.flashCache.clear()
        this.branchCache.clear()
        this.flashCache.set(EmptyObjectHash, EmptyObject)
        // @ts-ignore
        this.flashCache.set(EmptyArrayHash, EmptyArray)
    }


    public async reset() {
        this.resetCaches()

        const branchIds = await this.flashDb.getBranchIds()

        for (let branchId of branchIds) {
            const history = await this.flashDb.getBranchHistory(branchId)

            for (let hash of history) {
                this.branchCache.set(branchId, hash)
            }
        }
    }


    static async createFlashStore(flashDb: IFlashDb, cacheSize = Number.MAX_SAFE_INTEGER): Promise<FlashStore> {
        const ds = new FlashStore(flashDb, cacheSize)
        await ds.reset()
        return ds
    }



    getDbName() {
        return this.flashDb.getName()
    }


    async hasCachedHash(hash: Hash): Promise<boolean> {
        return this.flashCache.has(hash)
    }

    async hasHash(hash: Hash): Promise<boolean> {
        return this.flashCache.has(hash) || this.flashDb.hasHash(hash)
    }

    async get(hash: Hash): Promise<Flash> {
        let flash = this.flashCache.get(hash)
        if (!flash) {
            const flashlets = await this.flashDb.getFlashlets(hash)
            if (flashlets) {
                flash = flashedItemFrom(flashlets)
                if (flash) {
                    this.flashCache.set(hash, flash)
                }
            }
        }
        return flash
    }


    async gatherPropPath(path: FlashPath, fromHash: Hash): Promise<FlashValue[]> {
        let flashValues: FlashValue[] = [new HardHash(fromHash)]
        await this.followPath(path, fromHash,(prop, value) => {
            flashValues.push(value)
        })
        return flashValues
    }


    async getPropValue(path: FlashPath, fromHash: Hash): Promise<FlashValue> {
        const propPath = await this.gatherPropPath(path, fromHash)
        return last(propPath)
    }


    async put(x: Flashable): Promise<Hash> {
        let flash: Flash

        if (isNullish(x)) {
            flash = null
        }
        else if (isString(x) || isDateObject(x)) {
            flash = x
        } else {

            const mapper = async (v: unknown) =>
                isFlashable(v) ? new HardHash(await this.put(v)) : v === undefined ? null : v

            if (isArray(x)) {
                flash = await mapAsync(x, mapper) as Flash

            } else if (isObject(x)) {
                flash = await mapObjectAsync(x, mapper) as Flash

            } else {
                throw new Error(`cant directly store item with type ${typeof x}`)
            }
        }

        const hash = rawHash(flash)
        if (!this.flashCache.has(hash)) {
            await this.flashDb.putSlice(flashletsFor(flash, hash))
            this.flashCache.set(hash, flash)
        }

        return hash
    }


    public async followPath(path: FlashPath, fromHash: Hash, follower: (prop: FlashProp, value: FlashValue, depth: number) => boolean | void): Promise<void> {

        let currentHash = fromHash

        let depth = 0
        for (let prop of path) {
            const flash = await this.get(currentHash)

            let childValue = this.getFlashValue(flash, prop)

            const shouldBreak = follower(prop, childValue, depth++)

            if (shouldBreak || isNullish(childValue) || isPrimitive(childValue)) {
                return
            } else {
                // is hard hash
                currentHash = childValue.toString()
            }
        }
    }


    async build<T extends Flashable = any>(flashValue: FlashValue): Promise<T> {

        if (isNullish(flashValue)) {
            return null
        } else if (isPrimitive(flashValue)) {
            return flashValue as T

        } else {
            const flash = await this.get(flashValue.toString())

            const mapper = async (e: FlashValue) => this.build(e)

            if (isString(flash) || isDateObject(flash) || isNullish(flash) ) {
                return flash as T
            }

            if (isArray(flash)) {
                return await mapAsync(flash, mapper) as unknown as T
            }
            return await mapObjectAsync(flash, mapper) as unknown as T
        }
    }

    getFlashValue(flash: Flash, prop: FlashProp): FlashValue {
        let nodeValue: FlashValue

        if (isDateObject(flash)) {
            throw `Cant get prop ${prop} on date ${flash}`

        } else if (isArray(flash)) {
            if (isNumber(prop)) {
                nodeValue = flash[prop]
            } else {
                throw `Invalid array index ${prop} of type ${typeof prop}`
            }

        } else if (isObject(flash)) {
            if (isString(prop)) {
                nodeValue = flash[prop]
            } else {
                throw `Invalid object prop type ${prop}`
            }

        } else {
            throw `Cant get prop ${prop} on string ${flash}`
        }
        return nodeValue
    }


    async getSlice(since: Bookmark): Promise<Flashlet[]> {
        console.log('FlashStore???')
        return this.flashDb.getSlice(since)
    }

    async putSlice(slice: Flashlet[]): Promise<Bookmark> {
        return this.flashDb.putSlice(slice)
    }


    getBranchHistory(branchId: BranchId): Hash[] {
        return [...this.branchCache.get(branchId) ?? []]
    }

    getBranchRootHash(branchId: BranchId): Hash {
        return last(this.branchCache.get(branchId)) ?? EmptyObjectHash
    }


    async getBranchRoot(branchId: BranchId): Promise<Flash> {
        return this.get(this.getBranchRootHash(branchId))
    }

    async pushBranchRootHash(branchId: BranchId, newHash: Hash, oldHash?: Hash) {
        const didPut = await this.flashDb.pushBranchRootHash(branchId, newHash, oldHash)
        if (!didPut) {
            return false
        }
        this.branchCache.set(branchId, newHash)
        return true
    }

    getBranchIds(): BranchId[] {
        return [...this.branchCache.keys()]
    }


    async getBookmark(): Promise<number> {
        return this.flashDb.getBookmark()
    }

    async dispose() {
        await this.flashDb.dispose()
        this.resetCaches()
    }
}

export function flashType(v: unknown): FlashPropType {

    if (isHardHash(v)) {
        return FlashPropTypes.hash
    } else if (isDateObject(v)) {
        return FlashPropTypes.Date
    } else if (isNullish(v)) {
        return FlashPropTypes.null
    } else return FlashPropTypes[(typeof v) as FlashPropType]
}




export function flashedItemFrom(flashes: Flashlet[]): Flash {
    if (!flashes) return undefined
    let flashed: Flash
    flashes.forEach((f) => {
        if (typeof f.tag === 'number') {
            flashed = flashed ?? []
            // @ts-ignore
            flashed[f.tag] = f.type === 'hash' ? new HardHash(f.value) : f.value
        } else if (typeof f.tag === 'string') {
            flashed = flashed ?? {}
            // @ts-ignore
            flashed[f.tag] = f.type === 'hash' ? new HardHash(f.value) : f.value
        } else if (f.type === 'Date') {
            flashed = dateFromIso(f.value)
        } else {
            flashed = f.value
        }
    })
    return flashed
}


export function flashletsFor(flash: Flash, hash: Hash): Flashlet[] {
    if (isNullish(flash)) {
        return null
    }

    if (isString(flash)) {
        return [{
            hash,
            tag: null,
            type: 'string',
            value: flash,
        }]
    }

    if (isDateObject(flash)) {
        return [{
            hash,
            tag: null,
            type: 'Date',
            value: toIsoDate(flash)
        }]
    }


    const mapper = (value: unknown, tag: string | number) => {
        let type = flashType(value)

        if (type === FlashPropTypes.hash) {
            value = value.toString()
        }

        return {
            hash,
            tag,
            type,
            value,
        }
    }

    if (isArray(flash)) {
        return flash.map(mapper)
    }

    if (isObject(flash)) {
        return Object.entries(flash).map(([a, b]) => mapper(b, a))
    } else {
        throw new Error(`cant directly store item with type ${typeof flash}: ${flash}`)
    }
}
