import {createToggleSignal, PopupListSelector} from '@peachy/client-kit'
import {Component, createSignal} from 'solid-js'
import styles from './CancelReason.module.css'

const CANCEL_REASONS = [
    'Product unsuitable / misunderstood',
    'Product too expensive',
    'Alternative product purchased',
    'Cover overlapped with pre-existing cover',
    'Product cancelled within cooling off period',
    'Product cancelled by underwriter',
    'Poor service/complaint',
    'Cover no longer required',
    'Other (administrative reason)',
    'Not known',
]

export const CancelReason:Component<{onSelect?: () => void}> = (props) => {

    const [currentReason, setCurrentReason] = createSignal('Please select a reason')
    const [reasonSelector, toggleReasonSelector] = createToggleSignal(false)

    const onSelect = (select: string) => {
        setCurrentReason(select)
        props.onSelect()
        toggleReasonSelector()
    }

    let spanRef: HTMLSpanElement

    return (<>
        <p>For <span ref={spanRef} onClick={toggleReasonSelector}>[{currentReason()}] <i class={'fa-solid fa-caret-down'} /></span></p>
        <PopupListSelector
            list={CANCEL_REASONS}
            enabled={reasonSelector()}
            locatorElement={spanRef}
            onSelect={onSelect}
            onDismiss={toggleReasonSelector}
            appearance={{
                list: styles.list,
                listItem: styles.listItem,
                selectedItem: styles.selectedItem
            }}
        />
    </>)
}
